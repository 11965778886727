var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"admin-knowledge-base-questions"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('good-data-table',{ref:"knowledgeBaseQuestions",attrs:{"button-label":_vm.$t('Add Questions'),"columns":_vm.questionsColumns,"total-column":'response.responseData.total',"data-column":'response.responseData.data',"api-endpoint":'/admin/knowledge/questions'},on:{"add-new-info":_vm.showAddModal}})],1)],1),_c('b-modal',{attrs:{"id":"knowledgebaseCategoryQuestions","no-close-on-backdrop":true,"title":_vm.dataInfo && _vm.dataInfo.id ? 'Edit Knowledge Base Questions' : 'Add Knowledge Base Questions',"size":"lg","ok-only":"","ok-title":_vm.$t('Save Changes')},on:{"hidden":function($event){_vm.showQuestionsModal = false},"ok":_vm.saveData},model:{value:(_vm.showQuestionsModal),callback:function ($$v) {_vm.showQuestionsModal=$$v},expression:"showQuestionsModal"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Question')}},[_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.dataInfo.question),callback:function ($$v) {_vm.$set(_vm.dataInfo, "question", $$v)},expression:"dataInfo.question"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Status')}},[_c('b-form-select',{attrs:{"options":[
              {
                text: 'Active',
                value: '1',
              },
              {
                text: 'Inactive',
                value: '0',
              } ]},model:{value:(_vm.dataInfo.status),callback:function ($$v) {_vm.$set(_vm.dataInfo, "status", $$v)},expression:"dataInfo.status"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Category Name')}},[_c('b-form-select',{attrs:{"options":_vm.knowledgeCategoryOptions},model:{value:(_vm.dataInfo.category_id),callback:function ($$v) {_vm.$set(_vm.dataInfo, "category_id", $$v)},expression:"dataInfo.category_id"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Answers')}},[_c('quill-editor',{attrs:{"options":_vm.snowOption},model:{value:(_vm.dataInfo.answer),callback:function ($$v) {_vm.$set(_vm.dataInfo, "answer", $$v)},expression:"dataInfo.answer"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }